import React from 'react'
import PropTypes from 'prop-types'

import PinSetupForm from './pin-setup-form'
import { CardWrapper, CardContent, CardInnerHeading } from '../../shared/card'

import styles from './form-account-pin.css'

const AccountPinForm = ({
  header,
  subHeader,
  ...props
}) => {
  return (
    <CardWrapper>
      <CardContent className={styles.cardContent}>
        <CardInnerHeading
          header={header}
          subHeader={subHeader}
        />
        <PinSetupForm
          {...props}
        />
      </CardContent>
    </CardWrapper>
  )
}

AccountPinForm.propTypes = {
  header: PropTypes.string.isRequired,
  subHeader: PropTypes.string.isRequired
}

export default AccountPinForm
