import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import { CardWrapper, CardContent } from '../../shared/card'
import Form from '../../../forms/layout-form'
import Row from '../../../forms/layout-row'
import Col from '../../../forms/layout-column'
import EditButton from '../../shared/edit-button'
import Input from '../../../forms/input'
import Button from '../../../forms/button'
import { PHONE_MAX_LENGTH } from '../../../../constants'

import styles from './form-account-details.css'

function AccountDetailsForm({
  values,
  handleChange,
  handleBlur,
  handleSubmit,
  handleReset,
  errors,
  error,
  touched,
  isSubmitting,
  isEditable,
  setIsEditable
}) {
  return (
    <CardWrapper>
      <CardContent className={styles.cardContent}>
        {
          values.graphqlError &&
          <div>Error fetching data...</div>
        }
        {
          !isEditable && !values.graphqlError && (
            <div className={styles.editButton}>
              <EditButton
                data-e2e="account-details-edit-button"
                onClick={() => setIsEditable(true)}
              />
            </div>
          )
        }
        <Form
          handleSubmit={handleSubmit}
          isSubmitting={isSubmitting}
          formClassName={styles.formClass}
        >
          <Row className={styles.rowClass}>
            <Col className={styles.columnClass}>
              <Input
                data-e2e="account-details-first-name"
                inputClass={styles.inputClass}
                label="First name"
                name="firstName"
                value={values.firstName}
                onChange={handleChange}
                onBlur={handleBlur}
                warning={(values.firstName || touched.firstName) && errors.firstName}
                readonly={!isEditable}
              />
            </Col>
            <Col className={styles.columnClass}>
              <Input
                data-e2e="account-details-last-name"
                inputClass={styles.inputClass}
                label="Last name"
                name="lastName"
                value={values.lastName}
                onChange={handleChange}
                onBlur={handleBlur}
                warning={(values.lastName || touched.lastName) && errors.lastName}
                readonly={!isEditable}
              />
            </Col>
          </Row>
          <Row className={styles.rowClass}>
            <Col className={styles.columnClass}>
              <Input
                data-e2e="account-details-email"
                inputClass={classNames(styles.inputClass, styles.email)}
                label="Email address"
                name="email"
                value={values.email}
                onChange={handleChange}
                onBlur={handleBlur}
                warning={(values.email || touched.email) && errors.email}
                readonly={!isEditable}
                readonlyClassName={styles.email}
              />
            </Col>
          </Row>
          <Row className={styles.rowClass}>
            <Col className={styles.columnClass}>
              <Input
                data-e2e="account-details-mobile-number"
                inputClass={styles.inputClass}
                name="mobile"
                label="Mobile number"
                value={values.mobile}
                onChange={handleChange}
                onBlur={handleBlur}
                warning={(values.mobile || touched.mobile) && errors.mobile}
                readonly={!isEditable}
                readonlyClassName={styles.mobile}
                inputmode="numeric"
                maxLength={PHONE_MAX_LENGTH}
              />
            </Col>
          </Row>
          {
            isEditable && (
              <Row>
                <Col>
                  <Button
                    data-e2e="account-details-save-button"
                    className={styles.submitButton}
                    disabled={isSubmitting}
                    text="Save"
                    error={error}
                  />
                </Col>
                <Col>
                  <Button
                    data-e2e="account-details-cancel-button"
                    className={styles.cancelButton}
                    disabled={isSubmitting}
                    text="Cancel"
                    secondary
                    onClick={() => { handleReset(); setIsEditable(false) }}
                  />
                </Col>
              </Row>
            )
          }
        </Form>
      </CardContent>
    </CardWrapper>
  )
}

AccountDetailsForm.propTypes = {
  values: PropTypes.oneOfType([PropTypes.object]).isRequired,
  errors: PropTypes.oneOfType([PropTypes.object]).isRequired,
  touched: PropTypes.oneOfType([PropTypes.object]).isRequired,
  error: PropTypes.string,
  handleSubmit: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
  handleBlur: PropTypes.func.isRequired,
  handleReset: PropTypes.func.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
  isEditable: PropTypes.bool.isRequired,
  setIsEditable: PropTypes.func.isRequired
}

AccountDetailsForm.defaultProps = {
  error: ''
}

export default AccountDetailsForm
