import React from 'react'
import PropTypes from 'prop-types'

import styles from './round-icon.css'

function RoundIcon({ src, alt }) {
  return (
    <img alt={alt} className={styles.roundIcon} src={src} />
  )
}

RoundIcon.propTypes = {
  src: PropTypes.string.isRequired,
  alt: PropTypes.string.isRequired
}

export default RoundIcon
