import React from 'react'

import Section from '../../../shared/section'
import PurchasingSecurityForm from '../../../../../containers/settings/my-account/account-details/purchasing-security/purchasing'
import { TESTERS } from '../../../../../lib/tester-classnames'

function ProfileSecurity() {
  return (
    <Section title="Purchasing Security" testerClassName={TESTERS.ACCOUNT.PURCHASING_SECURITY}>
      <PurchasingSecurityForm />
    </Section>
  )
}

export default ProfileSecurity
