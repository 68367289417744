import React from 'react'
import PropTypes from 'prop-types'

import { CardWrapper, CardContent, CardInnerHeading } from '../../../shared/card'
import Toggle from '../../../shared/toggle'

import styles from './form-purchasing-restriction.css'

function PurchasingRestrictionForm({
  pinIsOn,
  passwordIsOn,
  hasNoPin,
  error,
  toggleTVODRestriction
}) {
  return (
    <CardWrapper>
      <CardContent className={styles.cardContent}>
        <CardInnerHeading
          header="Password / PIN purchase settings"
          subHeader="To keep your account secure we require you to enter your account password when making
          a purchase and using a saved credit card. Alternatively, you can use your PIN to validate your movie rentals.
          Select one from the security options below."
        />
        <Toggle
          data-e2e="purchasing-security-restriction-password"
          wrapperClassName={styles.passwordProtection}
          textWrapperClassName={styles.textWrapperClass}
          containerClassName={styles.toggleTextClass}
          text="Password protection"
          subText="Requires password to rent movies using a saved credit card."
          toggleText={passwordIsOn ? 'On' : 'Off'}
          checked={passwordIsOn}
          onChange={toggleTVODRestriction}
          tooltipText={hasNoPin ? 'Please setup a PIN to disable this option.' : ''}
          disabled={hasNoPin}
        />

        <Toggle
          data-e2e="purchasing-security-restriction-PIN"
          wrapperClassName={styles.toggleWrapperClass}
          textWrapperClassName={styles.textWrapperClass}
          containerClassName={styles.toggleTextClass}
          text="PIN protection"
          subText="Requires PIN to rent movies using a saved credit card."
          toggleText={pinIsOn ? 'On' : 'Off'}
          checked={pinIsOn}
          onChange={toggleTVODRestriction}
          tooltipText={hasNoPin ? 'Please setup a PIN to enable this option.' : ''}
          disabled={hasNoPin}
        />

        <div className={styles.warningMessage}>{error}</div>
      </CardContent>
    </CardWrapper>
  )
}

PurchasingRestrictionForm.propTypes = {
  pinIsOn: PropTypes.bool.isRequired,
  passwordIsOn: PropTypes.bool.isRequired,
  hasNoPin: PropTypes.bool.isRequired,
  error: PropTypes.string,
  toggleTVODRestriction: PropTypes.func.isRequired
}

PurchasingRestrictionForm.defaultProps = {
  error: ''
}

export default PurchasingRestrictionForm
