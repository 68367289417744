import React from 'react'
import PropTypes from 'prop-types'

import RoundIcon from './round-icon'

import styles from './edit-button.css'

import pencilIcon from '../../../../images/pencil.svg'

function EditButton({ text, onClick, ...other }) {
  return (
    <div // eslint-disable-line jsx-a11y/no-static-element-interactions
      className={styles.editButton}
      onClick={onClick}
      {...other}
    >
      <RoundIcon alt="Edit Icon" src={pencilIcon} />
      <span>{text}</span>
    </div>
  )
}

EditButton.propTypes = {
  text: PropTypes.string,
  onClick: PropTypes.func.isRequired
}

EditButton.defaultProps = {
  text: 'Edit'
}

export default EditButton
