import React from 'react'
import PropTypes from 'prop-types'

import Form from '../../../forms/layout-form'
import Row from '../../../forms/layout-row'
import Col from '../../../forms/layout-column'
import PinInput from '../../../forms/pin-input'
import Button from '../../../forms/button'

import { FORM_VALUES } from '../../../../constants'

import styles from './pin-setup-form.css'

function SetupPinForm({
  values,
  handleChange,
  handleBlur,
  handleSubmit,
  handleReset,
  errors,
  error,
  touched,
  isSubmitting,
  hasPin,
  displayForm,
  setDisplayForm,
  cta
}) {
  if (!displayForm) {
    return (
      <Button
        className={styles.setupPinButton}
        text={cta}
        onClick={() => setDisplayForm(true)}
      />
    )
  }
  return (
    <Form
      handleSubmit={handleSubmit}
      isSubmitting={isSubmitting}
      formClassName={styles.formClass}
    >
      <Row>
        <Col>
          <PinInput
            label={`${hasPin ? 'Change' : 'Enter new'} PIN`}
            name="pin"
            value={values.pin}
            onChange={handleChange}
            onBlur={handleBlur}
            warning={touched.pin && errors.pin}
            minLength="0"
            maxLength={`${FORM_VALUES.pin.length}`}
          />
          {
            !(touched.pin && errors.pin) && (values.pin) && (
              <span className={styles.enterPinHelpText}>
                Please create your 4-digit account PIN
              </span>
            )
          }
        </Col>
      </Row>
      <Row>
        <Col>
          <PinInput
            label={`${hasPin ? 'Confirm' : 'Confirm new'} PIN`}
            name="pinConfirmation"
            value={values.pinConfirmation}
            onChange={handleChange}
            onBlur={handleBlur}
            warning={touched.pinConfirmation && errors.pinConfirmation}
            isValid={Boolean(
              values.pinConfirmation &&
              !errors.pinConfirmation
            )}
            minLength="0"
            maxLength={`${FORM_VALUES.pin.length}`}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <Button
            className={styles.submitButton}
            disabled={isSubmitting}
            text="Save"
            error={error}
          />
        </Col>
        <Col>
          <Button
            secondary
            text="Cancel"
            className={styles.cancelButton}
            disabled={isSubmitting}
            onClick={() => { handleReset(); setDisplayForm(false) }}
          />
        </Col>
      </Row>
    </Form>
  )
}

SetupPinForm.propTypes = {
  values: PropTypes.oneOfType([PropTypes.object]).isRequired,
  errors: PropTypes.oneOfType([PropTypes.object]).isRequired,
  touched: PropTypes.oneOfType([PropTypes.object]).isRequired,
  error: PropTypes.string,
  handleSubmit: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
  handleBlur: PropTypes.func.isRequired,
  handleReset: PropTypes.func.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
  hasPin: PropTypes.bool.isRequired,
  displayForm: PropTypes.bool.isRequired,
  setDisplayForm: PropTypes.func.isRequired,
  cta: PropTypes.string.isRequired
}

SetupPinForm.defaultProps = {
  error: ''
}

export default SetupPinForm
