import React from 'react'

import Section from '../../../shared/section'
import OptInsForm from '../../../../../containers/settings/my-account/account-details/communication-settings/opt-ins-form'
import { TESTERS } from '../../../../../lib/tester-classnames'

function CommunicationSettings() {
  return (
    <Section title="Communication Settings" testerClassName={TESTERS.ACCOUNT.COMMUNICATION_SETTINGS}>
      <OptInsForm />
    </Section>
  )
}

export default CommunicationSettings
