import { Formik } from 'formik'
import { graphql } from 'react-apollo'
import {
  compose, branch, renderNothing, withState
} from 'recompose'
import {
  pathOr,
  trim
} from 'ramda'

import Yup from '../../../../lib/yup'
import { getGQLErrorMsg } from '../../../../lib/apollo'

import withIsAuthenticatedAndRedirect from '../../../../hoc/is-authenticated-redirect'
import {
  FORM_VALUES,
  FORM_MESSAGES,
  PHONE_REG_EXP
} from '../../../../constants'

import AccountDetailsForm from '../../../../components/settings/my-account/account-details/form-account-details'

import ACCOUNT_QUERY from '../../../../../graphql/queries/account.gql'
import ACCOUNT_MUTATION from '../../../../../graphql/mutations/account.gql'

import {
  enableLoadingOverlay,
  endLoadingOverlay,
  updateAuthToken
} from '../../../../actions'

const enhance = compose(
  withIsAuthenticatedAndRedirect,
  graphql(ACCOUNT_QUERY, {
    name: 'accountQuery',
    skip: ownProps => !ownProps.isAuthenticated
  }),
  branch(
    ({ accountQuery }) => accountQuery.loading,
    renderNothing
  ),
  graphql(ACCOUNT_MUTATION),
  withState('isEditable', 'setIsEditable', false),
  Formik({
    // Define the form's validation schema with Yup.
    validationSchema: Yup.object().shape({
      firstName: Yup.string().required(FORM_MESSAGES.required),
      lastName: Yup.string().required(FORM_MESSAGES.required),
      email: Yup.string().email(FORM_MESSAGES.email.valid).required(FORM_MESSAGES.required),
      mobile: Yup.string().trim()
        .min(FORM_VALUES.mobile.min, FORM_MESSAGES.mobile.min)
        .matches(PHONE_REG_EXP, { message: FORM_MESSAGES.mobile.match, excludeEmptyString: true })
        .max(FORM_VALUES.mobile.max, FORM_MESSAGES.mobile.max)
    }),

    // Map the field values to props if necessary. Used as empty strings for empty forms
    mapPropsToValues: ({ accountQuery }) => {
      if (
        (accountQuery && accountQuery.error) ||
        (accountQuery && !accountQuery.account)
      ) {
        return {
          graphqlError: accountQuery.error
        }
      }
      const {
        name,
        surname,
        email,
        phoneNumbers
      } = accountQuery.account

      return ({
        firstName: name || '',
        lastName: surname || '',
        email: email || '',
        mobile: (phoneNumbers && trim(phoneNumbers.mobile || '')) || ''
      })
    },

    // Define the payload object received in handleSubmit.
    mapValuesToPayload: values => ({
      name: values.firstName,
      surname: values.lastName,
      email: values.email,
      phoneNumbers: {
        mobile: trim(values.mobile)
      }
    }),

    // Formik lets you colocate your submission handler with your form.
    // In addition to the payload (the result of mapValuesToPayload), you have
    // access to all props and some stateful helpers.
    handleSubmit: (payload, {
      props, setError, setSubmitting, setFieldValue
    }) => {
      // handleSubmit: (payload, { props, setError, setSubmitting }) => {
      // e.preventDefault(), setSubmitting, setError(undefined) are
      // called before handleSubmit is. So you don't have to do repeat this.
      // handleSubmit will only be executed if form values pass Yup validation.
      setError('')

      const key = new Date().getTime()
      props.dispatch(enableLoadingOverlay(key, 'profilesEdit'))

      props.mutate({
        variables: {
          input: payload
        }
      })
        .then((res) => {
          const token = res.data.account.session.token
          setSubmitting(false)
          // update mobile value with the trimmed one
          setFieldValue('mobile', pathOr(null, ['data', 'account', 'phoneNumbers', 'mobile'], res))
          props.setIsEditable(false)
          props.dispatch(updateAuthToken(token, true))
          props.dispatch(endLoadingOverlay(key, 'profilesEdit', { timeout: 2000 }))
        })
        .catch((error) => {
          // display error
          console.info('error', JSON.stringify(error))
          setSubmitting(false)
          setError(getGQLErrorMsg(error))
          props.dispatch(endLoadingOverlay(key, 'profilesEdit', { error }))
        })
    }
  })
)

export default enhance(AccountDetailsForm)
