import React from 'react'

import AccountDetails from './my-account/account-details'
import ProfileSecurity from './my-account/account-details/profile-security'
import PurchasingSecurity from './my-account/account-details/purchasing-security'
import VideoSettings from './my-account/account-details/video-settings'
import CommunicationSettings from './my-account/account-details/communication-settings'

function Settings() {
  return (
    <>
      <AccountDetails />
      <ProfileSecurity />
      <PurchasingSecurity />
      <VideoSettings />
      <CommunicationSettings />
    </>
  )
}

export default Settings
