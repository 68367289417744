import { Formik } from 'formik'
import { graphql } from 'react-apollo'
import {
  compose,
  branch,
  renderNothing,
  withProps,
  withStateHandlers
} from 'recompose'
import { path } from 'ramda'

import Yup from '../../../../lib/yup'
import { getGQLErrorMsg } from '../../../../lib/apollo'

import AccountPinForm from '../../../../components/settings/my-account/account-details/form-account-pin'
import withMutateAndLoading from '../../../../hoc/with-mutate-and-loading'
import { FORM_VALUES, FORM_MESSAGES } from '../../../../constants'
import {
  PIN_HEADER_NO_PIN,
  PIN_HEADER_HAS_PIN,
  PIN_SUBHEADER_NO_PIN,
  PIN_SUBHEADER_HAS_PIN,
  PIN_SUBHEADER_EDITABLE,
  PIN_CTA_NO_PIN,
  PIN_CTA_HAS_PIN
} from './constants'

import ACCOUNT_QUERY from '../../../../../graphql/queries/account.gql'
import ACCOUNT_MUTATION from '../../../../../graphql/mutations/account.gql'

const getPinSubHeader = (hasPin, displayForm) => {
  if (displayForm) {
    return PIN_SUBHEADER_EDITABLE
  }

  return hasPin ? PIN_SUBHEADER_HAS_PIN : PIN_SUBHEADER_NO_PIN
}

const enhance = compose(
  graphql(ACCOUNT_QUERY, {
    name: 'accountQuery'
  }),
  branch(
    ({ accountQuery }) => accountQuery && (accountQuery.loading || accountQuery.error),
    renderNothing
  ),
  graphql(ACCOUNT_MUTATION),
  withMutateAndLoading,
  withStateHandlers(
    {
      displayForm: false
    }, {
      setDisplayForm: () => displayForm => ({
        displayForm
      })
    }
  ),
  withProps(({ accountQuery, displayForm }) => {
    const hasPin = path(['account', 'hasPin'], accountQuery)

    return ({
      hasPin,
      header: hasPin ? PIN_HEADER_HAS_PIN : PIN_HEADER_NO_PIN,
      subHeader: getPinSubHeader(hasPin, displayForm),
      cta: hasPin ? PIN_CTA_HAS_PIN : PIN_CTA_NO_PIN
    })
  }),
  Formik({
    validateOnBlur: true,
    validateOnChange: false,
    // Define the form's validation schema with Yup.
    validationSchema: Yup.object().shape({
      pin: Yup.string()
        .min(FORM_VALUES.pin.length, FORM_MESSAGES.pin.min)
        .max(FORM_VALUES.pin.length, FORM_MESSAGES.pin.max)
        .matches(/^[0-9]*$/, { message: FORM_MESSAGES.pin.valid, excludeEmptyString: false })
        .required(FORM_MESSAGES.required),
      pinConfirmation: Yup.string()
        .required(FORM_MESSAGES.required)
        .sameAs(Yup.ref('pin'), FORM_MESSAGES.pin.match)
    }),

    // Map the field values to props if necessary. Used as empty strings for empty forms
    mapPropsToValues: () => {
      return ({
        pin: '',
        pinConfirmation: ''
      })
    },

    // Formik lets you colocate your submission handler with your form.
    // In addition to the payload (the result of mapValuesToPayload), you have
    // access to all props and some stateful helpers.
    handleSubmit: (values, { props, setError, setSubmitting }) => {
      // handleSubmit: (payload, { props, setError, setSubmitting }) => {
      // e.preventDefault(), setSubmitting, setError(undefined) are
      // called before handleSubmit is. So you don't have to do repeat this.
      // handleSubmit will only be executed if form values pass Yup validation.
      setError('')
      const {
        setDisplayForm,
        mutateAndLoading
      } = props

      mutateAndLoading('pinSetup', {
        variables: {
          input: {
            pin: values.pin
          }
        }
      })
        .then(() => {
          // store auth token and go to home page logged in
          setSubmitting(false)
          setDisplayForm(false)
        })
        .catch((error) => {
          setSubmitting(false)
          setError(getGQLErrorMsg(error))
        })
    }
  })
)

export default enhance(AccountPinForm)
