import React, { Suspense } from 'react'

const wrap = (proto, method) => {
  const orig = proto[method]
  proto[method] = function newFunc(...args) {
    try {
      orig.apply(this, args)
    } catch (e) {
      console.warn(e)
    }
  }
}

const ReactTippy = React.lazy(() => import('react-tippy').then(res => {
  wrap(res.Tooltip.prototype, '_updateSettings')
  wrap(res.Tooltip.prototype, '_destroyTippy')
  return { default: res.Tooltip }
}))

const AsyncReactTippy = props => {
  return (
    <Suspense fallback={null}>
      <ReactTippy {...props} />
    </Suspense>
  )
}

export default AsyncReactTippy
