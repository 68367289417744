import { graphql } from 'react-apollo'
import {
  compose,
  branch,
  renderNothing,
  withState,
  withHandlers,
  mapProps,
  onlyUpdateForPropTypes
} from 'recompose'
import { pathEq } from 'ramda'

import { TVOD_PURCHASE_RESTRICTION } from '../../../../../constants'
import { getGQLErrorMsg } from '../../../../../lib/apollo'
import AccountPurchasingForm from '../../../../../components/settings/my-account/account-details/purchasing-security/form-purchasing-restriction'
import withIsAuthenticatedAndRedirect from '../../../../../hoc/is-authenticated-redirect'

import ACCOUNT_QUERY from '../../../../../../graphql/queries/account.gql'
import ACCOUNT_MUTATION from '../../../../../../graphql/mutations/account.gql'

const enhance = compose(
  withIsAuthenticatedAndRedirect,
  graphql(ACCOUNT_QUERY, {
    name: 'accountQuery',
    skip: ownProps => !ownProps.isAuthenticated,
    props: ({ ownProps, accountQuery }) => ({
      accountQuery,
      hasNoPin: pathEq(['account', 'hasPin'], false, accountQuery),
      pinIsOn: pathEq(
        ['account', 'settings', 'tvodPurchaseRestriction'], TVOD_PURCHASE_RESTRICTION.PIN, accountQuery
      ),
      passwordIsOn: pathEq(
        ['account', 'settings', 'tvodPurchaseRestriction'], TVOD_PURCHASE_RESTRICTION.PASSWORD, accountQuery
      ),
      ...ownProps
    })
  }),
  branch(
    ({ accountQuery }) => accountQuery.loading,
    renderNothing
  ),
  graphql(ACCOUNT_MUTATION),
  // property on component, function to set the prop, default value
  withState('error', 'setError', ''),
  withHandlers({
    changePurchaseRestriction: ({ mutate, setError, accountQuery }) => {
      return (value) => {
        mutate({
          variables: {
            input: { // AccountInput
              settings: {
                tvodPurchaseRestriction: value
              }
            }
          },
          optimisticResponse: {
            account: {
              ...accountQuery.account,
              settings: {
                ...accountQuery.account.settings,
                tvodPurchaseRestriction: value
              }
            }
          },
          update: (proxy, response) => {
            // Get account data from Apollo Store
            const data = proxy.readQuery({ query: ACCOUNT_QUERY })

            // Update tvodPurchaseRestriction in account
            data.account.settings.tvodPurchaseRestriction =
              response.data.account.settings.tvodPurchaseRestriction

            // Update store with updated account
            proxy.writeQuery({ query: ACCOUNT_QUERY, data })
          }
        })
          .catch((error) => {
            setError(getGQLErrorMsg(error))
          })
      }
    }
  }),
  withHandlers({
    toggleTVODRestriction: ({
      changePurchaseRestriction, pinIsOn
    }) => () => {
      const value = pinIsOn
        ? TVOD_PURCHASE_RESTRICTION.PASSWORD
        : TVOD_PURCHASE_RESTRICTION.PIN
      changePurchaseRestriction(value)
    }
  }),
  mapProps(({
    pinIsOn,
    passwordIsOn,
    hasNoPin,
    error,
    toggleTVODRestriction
  }) => {
    return {
      pinIsOn,
      passwordIsOn,
      hasNoPin,
      error,
      toggleTVODRestriction
    }
  }),
  onlyUpdateForPropTypes
)

export default enhance(AccountPurchasingForm)
