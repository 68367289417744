import { graphql } from 'react-apollo'
import {
  compose,
  branch,
  renderNothing,
  withState,
  withHandlers
} from 'recompose'

import { getGQLErrorMsg } from '../../../../../lib/apollo'
import AccountOptInsForm from '../../../../../components/settings/my-account/account-details/communication-settings/form-opt-ins'
import withIsAuthenticatedAndRedirect from '../../../../../hoc/is-authenticated-redirect'

import ACCOUNT_QUERY_WITH_DIR from '../../../../../../graphql/queries/account-with-directives.gql'
import ACCOUNT_MUTATION from '../../../../../../graphql/mutations/account.gql'

const enhance = compose(
  withIsAuthenticatedAndRedirect,
  graphql(ACCOUNT_QUERY_WITH_DIR, {
    name: 'accountQuery',
    options: {
      fetchPolicy: 'network-only',
      variables: {
        withOptIns: true,
        withClearCachedSubscriptions: true
      }
    },
    skip: ownProps => !ownProps.isAuthenticated
  }),
  branch(({ accountQuery }) => accountQuery.loading, renderNothing),
  graphql(ACCOUNT_MUTATION), // causes props.mutate to get assigned
  // property on component, function to set the prop, default value
  withState('error', 'setError', ''),
  withHandlers({
    changeOptIn: ({ mutate, setError }) => {
      return (evt, optIn, subscribed) => {
        mutate({
          variables: {
            input: {
              // AccountInput
              optIns: [{ id: optIn.id, subscribed }]
            }
          },
          optimisticResponse: {
            __typename: 'Mutation',
            account: {
              __typename: 'Account',
              optIns: [
                {
                  __typename: 'AccountOptIn',
                  id: optIn.id,
                  subscribed
                }
              ]
            }
          }
        }).catch(error => {
          // TODO: error handling
          setError(getGQLErrorMsg(error))
        })
      }
    }
  })
)

const AccountOptInsFormContainer = enhance(AccountOptInsForm)
AccountOptInsFormContainer.displayName = 'OptInsFormContainer'

export default AccountOptInsFormContainer
