import { Formik } from 'formik'
import { graphql } from 'react-apollo'
import { connect } from 'react-redux'
import { compose, withState } from 'recompose'
import { withRouter } from 'react-router-dom'

import Yup from '../../../../lib/yup'
import { getGQLErrorMsg } from '../../../../lib/apollo'

import ChangePasswordForm from '../../../../components/settings/my-account/account-details/form-change-password'

import { FORM_VALUES, FORM_MESSAGES } from '../../../../constants'
import UPDATE_PASSWORD_MUTATION from '../../../../../graphql/mutations/change-password.gql'

import withMutateAndLoading from '../../../../hoc/with-mutate-and-loading'

const enhance = compose(
  withRouter,
  graphql(UPDATE_PASSWORD_MUTATION),
  withMutateAndLoading, // add mutateWithLoading with loading screen handling
  withState('isEditable', 'setIsEditable', false),
  connect(
    () => ({}),
    dispatch => ({
      dispatch
    })
  ),
  Formik({
    validationSchema: Yup.object().shape({
      oldPassword: Yup.string().required(FORM_MESSAGES.required).min(
        FORM_VALUES.password.min,
        FORM_MESSAGES.password.min
      ),
      newPassword: Yup.string().required(FORM_MESSAGES.required).min(
        FORM_VALUES.password.min,
        FORM_MESSAGES.password.min
      ),
      newPasswordConfirmation: Yup.string().required(FORM_MESSAGES.required)
        .sameAs(Yup.ref('newPassword'), FORM_MESSAGES.password.match)
    }),

    // Map the field values to props if necessary. Used as empty strings for empty forms
    mapPropsToValues: () => {
      return ({
        oldPassword: '',
        newPassword: '',
        newPasswordConfirmation: ''
      })
    },

    // Formik lets you colocate your submission handler with your form.
    // In addition to the payload (the result of mapValuesToPayload), you have
    // access to all props and some stateful helpers.
    handleSubmit: (values, { props, setError, setSubmitting }) => {
      // e.preventDefault(), setSubmitting, setError(undefined) are
      // called before handleSubmit is. So you don't have to do repeat this.
      // handleSubmit will only be executed if form values pass Yup validation.
      setError('')

      props.mutateAndLoading('changePassword', {
        variables: {
          oldPassword: values.oldPassword,
          newPassword: values.newPassword
        }
      })
        .then(() => {
          setSubmitting(false)
          props.setIsEditable(false)
          values.oldPassword = ''
          values.newPassword = ''
          values.newPasswordConfirmation = ''
        })
        .catch((error) => {
          // display error
          setSubmitting(false)
          setError(getGQLErrorMsg(error))
        })
    }
  })
)

export default enhance(ChangePasswordForm)
