import React from 'react'
import PropTypes from 'prop-types'

import { CardWrapper, CardContent } from '../../../shared/card'
import Checkbox from '../../../../forms/checkbox'

import styles from './form-opt-ins.css'

function OptInsForm({
  accountQuery,
  changeOptIn
}) {
  const optIns = accountQuery.account.optIns || []
  if (!optIns.length) {
    return null
  }

  const inputs = optIns.map((optIn) => {
    return (
      <Checkbox
        className={styles.checkbox}
        labelClassName={styles.labelText}
        key={optIn.id}
        id={optIn.id}
        isChecked={optIn.subscribed}
        label={optIn.text}
        onChange={changeOptIn}
        value={optIn}
      />
    )
  })

  return (
    <CardWrapper>
      <CardContent className={styles.cardContent}>
        {inputs}
      </CardContent>
    </CardWrapper>
  )
}

OptInsForm.propTypes = {
  accountQuery: PropTypes.oneOfType([PropTypes.object]).isRequired,
  changeOptIn: PropTypes.func.isRequired
}

OptInsForm.defaultProps = {
  // data from account query: accountQuery.account
  accountQuery: {},
  changeOptIn: () => { }
}

export default OptInsForm
