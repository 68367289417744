import React from 'react'
import PropTypes from 'prop-types'

import { CardWrapper, CardContent } from '../../../shared/card'
import Toggle from '../../../shared/toggle'

import styles from './form-video-settings.css'

function VideoSettingsForm({
  isTrailerAutoplayOn,
  updateAccountSetting,
  error
}) {
  const onToggleChange = event => {
    updateAccountSetting(event.target.value, !event.target.checked)
  }

  return (
    <CardWrapper>
      <CardContent className={styles.cardContent}>
        <Toggle
          data-e2e="video-settings-autoplay-trailer"
          wrapperClassName={styles.toggleWrapperClass}
          textWrapperClassName={styles.textWrapperClass}
          containerClassName={styles.toggleTextClass}
          text="Autoplay trailers"
          subText="Autoplay trailers while browsing on all devices. This setting doesn’t affect autoplay next episode in a series."
          toggleText={isTrailerAutoplayOn ? 'On' : 'Off'}
          value="turnOffTrailerAutoplay"
          checked={isTrailerAutoplayOn}
          onChange={onToggleChange}
        />

        <div className={styles.warningMessage}>{error}</div>
      </CardContent>
    </CardWrapper>
  )
}

VideoSettingsForm.propTypes = {
  isTrailerAutoplayOn: PropTypes.bool.isRequired,
  updateAccountSetting: PropTypes.func.isRequired,
  error: PropTypes.string
}

VideoSettingsForm.defaultProps = {
  error: ''
}

export default VideoSettingsForm
