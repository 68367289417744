import React from 'react'
import Section from '../../shared/section'
import AccountDetailsForm from '../../../../containers/settings/my-account/account-details/account-details-form'
import ChangePasswordForm from '../../../../containers/settings/my-account/account-details/change-password-form'
import { TESTERS } from '../../../../lib/tester-classnames'

function AccountDetails() {
  return (
    <Section title="Account Details" testerClassName={TESTERS.ACCOUNT.DETAILS}>
      <AccountDetailsForm />
      <ChangePasswordForm />
    </Section>
  )
}

export default AccountDetails
