import React from 'react'

import Section from '../../../shared/section'

import PinSettings from '../../../../../containers/settings/my-account/account-details/profile-security/pin-settings'
import AccountPinForm from '../../../../../containers/settings/my-account/account-details/account-pin-form'
import { TESTERS } from '../../../../../lib/tester-classnames'

function ProfileSecurity() {
  return (
    <Section title="Profile Security" testerClassName={TESTERS.ACCOUNT.PROFILE_SECURITY}>
      <AccountPinForm />
      <PinSettings />
    </Section>
  )
}

export default ProfileSecurity
