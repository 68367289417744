import React from 'react'
import PropTypes from 'prop-types'
import ReactToggle from 'react-toggle'
import classNames from 'classnames'

import AsyncReactTippy from '../../../containers/async-react-tippy/index'

import styles from './toggle.css'

function Toggle({
  checked,
  toggleText,
  text,
  subText,
  tooltipText,
  wrapperClassName,
  textWrapperClassName,
  containerClassName,
  ...props
}) {
  const checkedClass = checked ? styles.checked : styles.unchecked
  const toggleWrapperClass = wrapperClassName || styles.wrapper
  const toggleContainerClass = containerClassName || styles.toggleContainer

  return (
    <div className={classNames(toggleWrapperClass)}>
      <div className={classNames(textWrapperClassName)}>
        <div className={styles.text}>{text}</div>
        <p className={styles.subText}>{subText}</p>
      </div>
      <div className={classNames(toggleContainerClass)}>
        <span className={checkedClass}>{toggleText}</span>
        {
          tooltipText ? (
            <AsyncReactTippy
              title={tooltipText}
              position="top"
              trigger="mouseenter"
              arrow
              duration="0"
              disabled={!tooltipText}
            >
              <ReactToggle
                {...props}
                checked={checked}
                icons={false}
              />
            </AsyncReactTippy>
          ) : (
            <ReactToggle
              {...props}
              checked={checked}
              icons={false}
            />
          )
        }
      </div>
    </div>
  )
}

Toggle.propTypes = {
  checked: PropTypes.bool,
  text: PropTypes.string,
  subText: PropTypes.string,
  toggleText: PropTypes.string,
  tooltipText: PropTypes.string,
  wrapperClassName: PropTypes.string,
  textWrapperClassName: PropTypes.string,
  containerClassName: PropTypes.string
}

Toggle.defaultProps = {
  checked: false,
  text: '',
  subText: '',
  toggleText: '',
  tooltipText: '',
  wrapperClassName: '',
  textWrapperClassName: '',
  containerClassName: ''
}

export default Toggle
