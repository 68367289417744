import { graphql } from 'react-apollo'
import {
  compose, branch, renderNothing, withProps, withHandlers
} from 'recompose'
import { path } from 'ramda'

import { getGQLErrorMsg } from '../../../../../lib/apollo'
import PinSettings from '../../../../../components/settings/my-account/account-details/profile-security/pin-settings'
import withIsAuthenticatedAndRedirect from '../../../../../hoc/is-authenticated-redirect'

import ACCOUNT_QUERY from '../../../../../../graphql/queries/account.gql'
import ACCOUNT_MUTATION from '../../../../../../graphql/mutations/account.gql'

const enhance = compose(
  withIsAuthenticatedAndRedirect,
  graphql(ACCOUNT_QUERY, {
    name: 'accountQuery',
    skip: ownProps => !ownProps.isAuthenticated
  }),
  branch(
    ({ accountQuery }) => accountQuery.loading,
    renderNothing
  ),
  graphql(ACCOUNT_MUTATION, {
    options: {
      update: (proxy, response) => {
        const data = proxy.readQuery({ query: ACCOUNT_QUERY })

        data.account.settings = response.data.account.settings
        proxy.writeQuery({ query: ACCOUNT_QUERY, data })
      }
    }
  }),
  withProps(({ accountQuery }) => {
    const getSetting = setting => path(['account', 'settings', setting])(accountQuery)
    const requirePinForManageProfile = getSetting('requirePinForManageProfile')
    const requirePinForSwitchProfile = getSetting('requirePinForSwitchProfile')
    const hasPin = path(['account', 'hasPin'], accountQuery)

    return {
      hasPin,
      requirePinForManageProfile,
      requirePinForSwitchProfile
    }
  }),
  withHandlers({
    updateAccountSetting: ({ accountQuery, mutate, setError }) => (setting, checked) => {
      mutate({
        variables: {
          input: { // AccountInput
            settings: {
              [setting]: checked
            }
          }
        },
        optimisticResponse: {
          account: {
            ...accountQuery.account,
            settings: {
              ...accountQuery.account.settings,
              [setting]: checked
            }
          }
        },
        update: (proxy, response) => {
          // Get account data from Apollo Store
          const data = proxy.readQuery({ query: ACCOUNT_QUERY })

          // Update session token and selected profile
          data.account.settings[setting] = response.data.account.settings[setting]

          // Update session token and selected profile
          proxy.writeQuery({ query: ACCOUNT_QUERY, data })
        }
      })
        .catch((error) => {
          setError(getGQLErrorMsg(error))
        })
    }
  })
)

export default enhance(PinSettings)
