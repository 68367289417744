import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import Form from '../../../forms/layout-form'
import Row from '../../../forms/layout-row'
import Col from '../../../forms/layout-column'
import PasswordInput from '../../../forms/password-input'
import Button from '../../../forms/button'

import { CardWrapper, CardContent, CardInnerHeading } from '../../shared/card'
import EditButton from '../../shared/edit-button'

import styles from './form-change-password.css'

function ChangePasswordForm({
  values,
  handleChange,
  handleBlur,
  handleSubmit,
  handleReset,
  error,
  errors,
  touched,
  isSubmitting,
  isEditable,
  setIsEditable
}) {
  return (
    <CardWrapper>
      <CardContent className={styles.cardContent}>
        <CardInnerHeading className={styles.cardInnerHeading} header="Account password" />
        {
          values.graphqlError &&
            <div>Error fetching data...</div>
        }
        {
          !isEditable && !values.graphqlError && (
            <div className={styles.editButton}>
              <EditButton text="Change password" onClick={() => setIsEditable(true)} />
            </div>
          )
        }
        {
          isEditable && (
            <Form
              handleSubmit={handleSubmit}
              isSubmitting={isSubmitting}
              formClassName={styles.formClass}
            >
              <Row>
                <Col>
                  <PasswordInput
                    wrapperClassName={styles.enterCurrentPw}
                    label="Enter Current Password"
                    name="oldPassword"
                    value={values.oldPassword}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    warning={(values.oldPassword || touched.oldPassword) && errors.oldPassword}
                  />
                </Col>
              </Row>
              <Row>
                <Col>
                  <PasswordInput
                    label="Enter New Password"
                    name="newPassword"
                    value={values.newPassword}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    warning={(values.newPassword || touched.newPassword) && errors.newPassword}
                  />
                </Col>
              </Row>
              <Row>
                <Col>
                  <PasswordInput
                    label="Confirm New Password"
                    name="newPasswordConfirmation"
                    value={values.newPasswordConfirmation}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    warning={(values.newPasswordConfirmation || touched.newPasswordConfirmation) &&
                    errors.newPasswordConfirmation}
                  />
                </Col>
              </Row>
              <Row>
                <Col>
                  <Button
                    className={styles.submitButton}
                    disabled={isSubmitting}
                    text="Save"
                    error={error}
                  />
                </Col>
                <Col>
                  <Button
                    secondary
                    className={styles.cancelButton}
                    disabled={isSubmitting}
                    text="Cancel"
                    onClick={() => { handleReset(); setIsEditable(false) }}
                  />
                </Col>
              </Row>
            </Form>
          )
        }
        {
          !isEditable && (
            <Row>
              <Col>
                <PasswordInput
                  inputClass={classNames(styles.inputClass)}
                  label="Password"
                  name="password"
                  type="password"
                  value={values.oldPassword}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  readonly={!isEditable}
                  readonlyTextOverwrite="****"
                  readonlyClassName={styles.pw}
                  warning={(values.oldPassword || touched.oldPassword) && errors.oldPassword}
                />
              </Col>
            </Row>
          )
        }
      </CardContent>
    </CardWrapper>
  )
}

ChangePasswordForm.propTypes = {
  values: PropTypes.oneOfType([PropTypes.object]).isRequired,
  error: PropTypes.string.isRequired, // eslint-disable-line react/forbid-prop-types
  errors: PropTypes.oneOfType([PropTypes.object]).isRequired,
  touched: PropTypes.oneOfType([PropTypes.object]).isRequired,
  handleReset: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
  handleBlur: PropTypes.func.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
  isEditable: PropTypes.bool.isRequired,
  setIsEditable: PropTypes.func.isRequired
}

ChangePasswordForm.defaultProps = {
  error: ''
}

export default ChangePasswordForm
