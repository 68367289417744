import React from 'react'
import PropTypes from 'prop-types'

import { CardWrapper, CardContent, CardInnerHeading } from '../../../shared/card'
import Toggle from '../../../shared/toggle'

import styles from './pin-settings.css'

function SetupPinForm({
  requirePinForManageProfile,
  requirePinForSwitchProfile,
  updateAccountSetting,
  hasPin
}) {
  const onToggleChange = (event) => {
    updateAccountSetting(event.target.value, event.target.checked)
  }

  const tooltipText = !hasPin ? 'Please setup a PIN to enable this option.' : ''

  return (
    <CardWrapper>
      <CardContent className={styles.cardContent}>
        <CardInnerHeading
          className={styles.cardInnerHeading}
          header="Use PIN for:"
        />
        <Toggle
          wrapperClassName={styles.changeProfile}
          textWrapperClassName={styles.textWrapperClass}
          containerClassName={styles.toggleTextClass}
          text="Switching profiles"
          subText="PIN required to switch from profile with a lower content rating restriction to a higher content rating restriction (e.g. from G to M)"
          toggleText={requirePinForSwitchProfile ? 'On' : 'Off'}
          value="requirePinForSwitchProfile"
          checked={requirePinForSwitchProfile}
          onChange={onToggleChange}
          disabled={!hasPin}
          tooltipText={tooltipText}
        />
        <Toggle
          wrapperClassName={styles.toggleWrapperClass}
          textWrapperClassName={styles.textWrapperClass}
          containerClassName={styles.toggleTextClass}
          text="Accessing profile settings"
          subText="PIN required to create new profiles or to edit existing profiles"
          toggleText={requirePinForManageProfile ? 'On' : 'Off'}
          value="requirePinForManageProfile"
          checked={requirePinForManageProfile}
          onChange={onToggleChange}
          disabled={!hasPin}
          tooltipText={tooltipText}
        />
      </CardContent>
    </CardWrapper>
  )
}

SetupPinForm.propTypes = {
  requirePinForManageProfile: PropTypes.bool.isRequired,
  requirePinForSwitchProfile: PropTypes.bool.isRequired,
  updateAccountSetting: PropTypes.func.isRequired,
  hasPin: PropTypes.bool.isRequired
}

SetupPinForm.defaultProps = {
  error: ''
}

export default SetupPinForm
